export const languages = [
  { label: "English", value: "en", icon: "🇬🇧" },
  { label: "French", value: "fr", icon: "🇫🇷" },
  { label: "German", value: "de", icon: "🇩🇪" },
  { label: "Spanish", value: "es", icon: "🇪🇸" },
  { label: "Portuguese", value: "pt", icon: "🇵🇹" },
  { label: "Ukrainian", value: "ua", icon: "🇺🇦" },
  { label: "Polish", value: "pl", icon: "🇵🇱" },
  { label: "Japanese", value: "ja", icon: "🇯🇵" },
  { label: "Korean", value: "ko", icon: "🇰🇷" },
  { label: "Chinese", value: "zh", icon: "🇨🇳" },
] as const;
