"use client";

import React from "react";
import { api } from "~/trpc/react";
import {
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Table,
  TableHeader,
} from "~/components/ui/table";

const UsageTable = () => {
  const usageQuery = api.user.getUsage.useQuery();
  const subscriptionQuery = api.user.getSubscription.useQuery();

  const allowedEnhancements = subscriptionQuery.data?.ENHANCEMENTS_PER_DAY;
  const usedEnhancements = usageQuery.data?.enhancements;

  const allowedTextMessages = subscriptionQuery.data?.TEXT_MESSAGES_PER_DAY;
  const usedTextMessages = usageQuery.data?.textMessages;

  const allowedVoiceMessages = subscriptionQuery.data?.VOICE_MESSAGES_PER_DAY;
  const usedVoiceMessages = usageQuery.data?.voiceMessages;

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-[100px]">Type</TableHead>
          <TableHead>Allowed</TableHead>
          <TableHead>Used</TableHead>
        </TableRow>
      </TableHeader>

      <TableBody>
        <TableRow>
          <TableCell>Enhancements</TableCell>
          <TableCell>{allowedEnhancements}</TableCell>
          <TableCell>{usedEnhancements ?? 0}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Text Messages</TableCell>
          <TableCell>{allowedTextMessages}</TableCell>
          <TableCell>{usedTextMessages ?? 0}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Voice Messages</TableCell>
          <TableCell>{allowedVoiceMessages}</TableCell>
          <TableCell>{usedVoiceMessages ?? 0}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default UsageTable;
