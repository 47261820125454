import React from "react";
import { type User } from "next-auth";
import { Avatar, AvatarFallback, AvatarImage } from "~/components/ui/avatar";
import Link from "next/link";

const SignedInUser = ({
  user,
  isCollapsed,
}: {
  user: User;
  isCollapsed: boolean;
}) => {
  return (
    <div className="flex cursor-pointer items-center justify-center px-4">
      <Link href="/user">
        <Avatar>
          {user.image && <AvatarImage src={user.image} />}
          {!user.image && (
            <AvatarFallback>{user.name?.slice(0, 1)}</AvatarFallback>
          )}
        </Avatar>
      </Link>
      {!isCollapsed && <div className="ml-2 font-extralight">{user.name}</div>}
    </div>
  );
};

export default SignedInUser;
