import { Button } from "~/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "~/components/ui/dialog";
import { useGlobalUiState } from "../app-shell";
import { CrownIcon } from "lucide-react";
import { api } from "~/trpc/react";

// Import table components
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table"; // Adjust the import paths as needed
import { useSession } from "next-auth/react";
import UsageTable from "../usage-table";

export function UpgradeModal() {
  const { isUpgradeDialogOpen, toggleUpgradeDialog } = useGlobalUiState();
  const { data: session } = useSession();

  const usageQuery = api.user.getUsage.useQuery();
  const subscriptionQuery = api.user.getSubscription.useQuery();

  const allowedEnhancements = subscriptionQuery.data?.ENHANCEMENTS_PER_DAY;
  const usedEnhancements = usageQuery.data?.enhancements;

  const allowedTextMessages = subscriptionQuery.data?.TEXT_MESSAGES_PER_DAY;
  const usedTextMessages = usageQuery.data?.textMessages;

  const allowedVoiceMessages = subscriptionQuery.data?.VOICE_MESSAGES_PER_DAY;
  const usedVoiceMessages = usageQuery.data?.voiceMessages;

  return (
    <Dialog open={isUpgradeDialogOpen} onOpenChange={toggleUpgradeDialog}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="flex gap-1 align-middle">
            <CrownIcon className="relative -top-1" />
            Go pro
          </DialogTitle>
          <DialogDescription>
            You have reached your limit of free <b>enhancements</b> or{" "}
            <b>messages</b>. Upgrade to Pro for unlimited access.
          </DialogDescription>
        </DialogHeader>

        {/* Adjusted Table with separate columns */}
        <UsageTable />
        <DialogFooter>
          <Button asChild>
            <a
              href={`https://fluendo.lemonsqueezy.com/checkout/buy/9c165518-2e80-4eca-af78-b1601a1220b2?checkout[custom][user_id]=${session?.user.id}`}
            >
              Upgrade
            </a>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
