"use client";

import * as React from "react";

import { cn } from "~/lib/utils";

import { Button } from "~/components/ui/button";

import { Loader2 } from "lucide-react";
import { SiDiscord, SiGoogle, SiTwitter } from "@icons-pack/react-simple-icons";
import { signIn } from "next-auth/react";

type UserAuthFormProps = React.HTMLAttributes<HTMLDivElement>;

export function UserAuthForm({ className, ...props }: UserAuthFormProps) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  async function onSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }

  return (
    <div className={cn("grid gap-6", className)} {...props}>
      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <span className="w-full border-t" />
        </div>
        <div className="relative flex justify-center text-xs uppercase">
          <span className="bg-background px-2 text-muted-foreground">
            Continue with
          </span>
        </div>
      </div>
      {/* <Button
        className="flex gap-2"
        variant="outline"
        type="button"
        disabled={isLoading}
      >
        {isLoading ? (
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
        ) : (
          <SiGithub size={24} />
        )}{" "}
        GitHub
      </Button> */}
      <Button
        className="flex gap-2"
        variant="outline"
        type="button"
        disabled={isLoading}
        onClick={() =>
          signIn("discord", { callbackUrl: "/scenarios/?loginState=signedIn" })
        }
      >
        {isLoading ? (
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
        ) : (
          <SiDiscord size={24} />
        )}{" "}
        Discord
      </Button>
      <Button
        className="flex gap-2"
        variant="outline"
        type="button"
        disabled={isLoading}
        onClick={() =>
          signIn("google", { callbackUrl: "/scenarios/?loginState=signedIn" })
        }
      >
        {isLoading ? (
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
        ) : (
          <SiGoogle size={24} />
        )}{" "}
        Google
      </Button>
      <Button
        className="flex gap-2"
        variant="outline"
        type="button"
        onClick={() =>
          signIn("twitter", { callbackUrl: "/scenarios/?loginState=signedIn" })
        }
        disabled={isLoading}
      >
        {isLoading ? (
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
        ) : (
          <SiTwitter size={24} />
        )}{" "}
        Twitter
      </Button>
    </div>
  );
}
