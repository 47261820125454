"use client";
import { Separator } from "~/components/ui/separator";
import { TooltipProvider } from "~/components/ui/tooltip";
import {
  LayoutDashboard,
  LogOut,
  MessageCircleMore,
  Settings,
  Popcorn,
  Link,
  SquareUserRound,
} from "lucide-react";
import React from "react";
import {
  ResizablePanelGroup,
  ResizableHandle,
  ResizablePanel,
} from "~/components/ui/resizable";
import { useSession } from "next-auth/react";
import { cn } from "~/lib/utils";
import { Nav } from "../navbar";
import { type Session } from "next-auth";
import { SessionProvider, signOut } from "next-auth/react";
import SignedInUser from "../signed-in-user";
import { Button, buttonVariants } from "~/components/ui/button";
import { UserAuthForm } from "../user-auth-form";

import posthog from "posthog-js";
import { PostHogProvider, usePostHog } from "posthog-js/react";
import { useEffect } from "react";
import { useParams } from "next/navigation";
import { usePathname, useSearchParams } from "next/navigation";
import { api } from "~/trpc/react";

import { create } from "zustand";
import { UpgradeModal } from "../upgrade-modal";

interface GlobalUiState {
  isUpgradeDialogOpen: boolean;
  toggleUpgradeDialog: () => void;
}

export const useGlobalUiState = create<GlobalUiState>((set) => ({
  isUpgradeDialogOpen: false,
  toggleUpgradeDialog: () => {
    set((state) => ({ isUpgradeDialogOpen: !state.isUpgradeDialogOpen }));
  },
}));

// Initialize PostHog client-side
if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY ?? "", {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST ?? "https://app.posthog.com",
    // Enable debug mode in development
    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") posthog.debug();
    },
  });
}

const AppShell = ({
  children,
  session,
}: {
  children: React.ReactNode;

  session: Session | null;
}) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const params = useParams();

  const shallShowSidebar = !params.id;

  // Track page views on route change
  useEffect(() => {
    const handleRouteChange = () => posthog.capture("$pageview");
    handleRouteChange();
  }, [pathname, searchParams]); // Adding dependencies to re-run the effect on changes

  if (!session) {
    return (
      <>
        <div className="container  flex-col items-center justify-center lg:flex lg:max-w-none lg:px-0">
          <Link
            href="/examples/authentication"
            className={cn(
              buttonVariants({ variant: "ghost" }),
              "absolute right-4 top-4 md:right-8 md:top-8 lg:hidden", // Hide on larger screens
            )}
          >
            Login
          </Link>
          <div className="grid h-[100vh] w-full grid-cols-1 lg:grid-cols-2">
            {/* Social Proof Section (left side on larger screens) */}
            <div className="hidden bg-slate-500 lg:block lg:p-8">
              <div className="w-full text-center">
                <svg
                  className="mx-auto h-8 text-gray-100"
                  fill="currentColor"
                  viewBox="0 0 150 29"
                  version="1.1"
                >
                  {/* SVG paths */}
                </svg>
              </div>
              <div className="relative mx-auto mt-24 max-w-screen-xl bg-gray-100 px-8 py-16 text-gray-900 shadow-lg sm:rounded-lg md:px-12 lg:px-16 xl:px-32">
                <div className="hero-pattern absolute bottom-0 right-0 h-56 w-64"></div>
                <div className="relative">
                  <div>
                    <h2 className="text-center text-3xl font-bold leading-tight text-gray-800 lg:text-5xl">
                      Start speaking confidently
                    </h2>
                    <div className="mx-auto my-4 h-2 w-12 border-4 border-yellow-500"></div>
                    <div className="text-center font-light text-gray-700">
                      Join others and learn language on demand with best AI
                      tutors
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Login and Account Creation UI (right side on larger screens) */}
            <div className="mx-auto flex flex-col justify-center space-y-6 sm:w-[350px] lg:p-8">
              <div className="text-center">
                <h1 className="mb-2 text-2xl font-semibold tracking-tight">
                  Sign in
                </h1>
                <p className="text-sm text-muted-foreground">
                  Use one of the providers to sign in
                </p>
              </div>
              <UserAuthForm />
              <p className="px-8 text-center text-sm text-muted-foreground">
                By clicking continue, you agree to our{" "}
                <a
                  href="https://www.fluendo.ai/toc"
                  target="_blank"
                  className="underline underline-offset-4 hover:text-primary"
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href="https://www.fluendo.ai/privacy"
                  target="_blank"
                  className="underline underline-offset-4 hover:text-primary"
                >
                  Privacy Policy
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <PostHogProvider client={posthog}>
      <SessionProvider session={session}>
        <PostHogIdentification>
          <TooltipProvider delayDuration={0}>
            <div className={`flex !h-[100vh] `}>
              <div
                className={`border-right border-[1px] border-gray-200 ${shallShowSidebar ? "lg:block" : "hidden"}`}
              >
                <div>
                  <div>
                    <Separator />
                    <Nav
                      isCollapsed={true}
                      links={[
                        {
                          title: "Scenarios",
                          to: "/scenarios",
                          icon: Popcorn,
                        },
                        {
                          title: "Tutors",
                          to: "/tutors",
                          icon: SquareUserRound,
                        },
                        {
                          title: "Chats",
                          to: "/chats",
                          icon: MessageCircleMore,
                        },
                      ]}
                    />
                    <Separator />
                    <Nav
                      isCollapsed={true}
                      links={[
                        {
                          title: "Settings",
                          to: "/settings",

                          icon: Settings,
                        },
                      ]}
                    />
                    <Separator />
                  </div>

                  <div className="z-50 mt-4">
                    {session?.user && (
                      <div className="flex flex-col items-center">
                        <div className="mb-1">
                          <SignedInUser
                            isCollapsed={true}
                            user={session.user}
                          />
                        </div>
                        <div>
                          <Button
                            size="sm"
                            variant="ghost"
                            className="h-9 w-9"
                            onClick={() => signOut()}
                          >
                            <LogOut className="w-[25px]" />
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="w-full !overflow-y-scroll">
                <GlobalData>
                  <div className="p-3">{children}</div>
                </GlobalData>
              </div>
            </div>
          </TooltipProvider>
        </PostHogIdentification>
      </SessionProvider>
    </PostHogProvider>
  );
};

export default AppShell;

const PostHogIdentification = ({ children }: { children: React.ReactNode }) => {
  const { data: session } = useSession();
  const posthog = usePostHog();

  const params = useSearchParams();
  const newLoginState = params.get("loginState");

  if (newLoginState == "signedIn" && session) {
    posthog.identify(session.user.id);
  }

  return <>{children}</>;
};

// // make some top level queries here, so they are already availalbe on any page in the app from cache
const GlobalData = ({ children }: { children: React.ReactNode }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const usageQuery = api.user.getUsage.useQuery();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const subscriptionQuery = api.user.getSubscription.useQuery();

  return (
    <>
      <UpgradeModal />
      {children}
    </>
  );
};
